import React, {Component} from 'react';
import {
    withRouter
} from "react-router-dom";
import {withTranslation} from "react-i18next";
import moment from 'moment';
import './track-player.scss';
import Hls from "hls.js";
import DecryptionService from '../../../services/decrypt-service'
import axios from "axios";
import authHeader from "../../../services/auth-header";
import { buf2hex } from "../../../Utils";
import _ from 'underscore';
import authService from "../../../services/authService";
import userService from "../../../services/userService";

class TrackPlayer extends Component {
    constructor() {
        super();

        this.state = {
            elapsedTime: 0,
            duration: 0,
            fading: false,
            pausedTime: null,
            loaded: false,
            play: false,
            paused: false,
            loading: false,
            infoOpen: false,
            blocked: false
        }
    }

    isApple = () => {
        return [
                'iPad Simulator',
                'iPhone Simulator',
                'iPod Simulator',
                'iPad',
                'iPhone',
                'iPod',
                'MacIntel',
                'Macintosh'
            ].includes(navigator.platform)
            // iPad on iOS 13 detection
            || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }

    componentDidMount() {
        const {currentTrack, playing, playerID, fadingTime, playNext, setRemoveSong} = this.props;
        const audio = document.getElementById(`player-${playerID}`);
        this.audio = audio;

        setRemoveSong(() => this.removeSong());


        window.AudioContext = window.AudioContext || window.webkitAudioContext;
        this.context = new AudioContext();

        const testNative = false;

        if (testNative || (this.audio.canPlayType('application/vnd.apple.mpegurl') && this.isApple())) {
            this.usedPlayer = 'native'
        } else if (!testNative && Hls.isSupported()) {
            this.usedPlayer = 'hls'
        } else {
            console.log("unsupported browser")
            //todo: show error to user that their browser sucks
        }

        audio.addEventListener("timeupdate", () => {
            const {fading} = this.state;
            const elapsed = audio.currentTime * 1000;
            const duration = audio.duration;
            const remaining = duration * 1000 - elapsed;

            if(remaining < fadingTime && !fading) {
                this.fadePlayer(false);
                this.setState({fading: true});
                playNext();
            }

            this.setState({elapsedTime: elapsed})
        }, false);

        audio.addEventListener('pause', (event) => {
            this.pausePlayer();
        });

        audio.addEventListener('play', (event) => {
            const {mainPlaying, play} = this.props;

            this.setState({paused: false})

            if(!mainPlaying) {
                play();
            }
        });

        audio.addEventListener('error', function failed(e) {
            // audio playback failed - show a message saying why
            // to get the source of the audio element use $(this).src
            switch (e.target.error.code) {
                case e.target.error.MEDIA_ERR_ABORTED:
                    alert('You aborted the video playback.');
                    break;
                case e.target.error.MEDIA_ERR_NETWORK:
                    alert('A network error caused the audio download to fail.');
                    break;
                case e.target.error.MEDIA_ERR_DECODE:
                    alert('The audio playback was aborted due to a corruption problem or because the video used features your browser did not support.');
                    break;
                case e.target.error.MEDIA_ERR_SRC_NOT_SUPPORTED:
                    alert('The video audio not be loaded, either because the server or network failed or because the format is not supported.');
                    break;
                default:
                    alert('An unknown error occurred.');
                    break;
            }
        }, true);

        audio.onloadedmetadata = (_) => {
            this.setState({duration: this.audio.duration});
        };

        audio.onended = (_) => {
            this.nextSong();
        };

        audio.oncanplay = () => {
            if (this.usedPlayer === 'native') {
                this.setState({loaded: true, loading: false});
            }
        }

        if(playing && currentTrack) {
            this.loadTrack(currentTrack, true, false);
        }

        this.checkInterval = setInterval( () => {
            const {playing} = this.props;
            const {paused, elapsedTime, lastElapsed, fading, loaded} = this.state;
            if(playing && loaded && !paused && !fading){
                if(lastElapsed === elapsedTime) {
                    this.removeSong();
                } else {
                    this.setState({lastElapsed: elapsedTime})
                }
            }
        }, 2000);
    }

    onLoaded() {
        const {play} = this.state;

        if (this.usedPlayer === 'hls') {
            this.setState({loaded: true, loading: false});
        }

        if(play) {
            this.playTrack();
        }
    }

    createHLSPlayer() {
        const {history} = this.props;

        let config = {
            maxBufferLength: 300,
            fragLoadPolicy: {
                default: {
                    maxTimeToFirstByteMs: 9000,
                    maxLoadTimeMs: 100000,
                    timeoutRetry: {
                        maxNumRetry: 2,
                        retryDelayMs: 0,
                        maxRetryDelayMs: 0,
                    },
                    errorRetry: {
                        maxNumRetry: 20,
                        retryDelayMs: 400,
                        maxRetryDelayMs: 30000,
                        backoff: 'linear',
                    },
                },
            },
        };

        const hls = new Hls(config);
        hls.attachMedia(this.audio);

        hls.on(Hls.Events.MANIFEST_PARSED, () => { this.onLoaded() });

        hls.on(Hls.Events.ERROR, (event, data) => {
            if(data && data.response && data.response.code === 401) {
                authService.logout();
                history.push('/login')
            } else if(data && data.response && data.response.code === 605) {
                authService.logout();
                window.location.replace("https://bcmstream.com/easy-webbased-trial")
            } else if (data && data.fatal && data.type) {
                console.log("Fatal HLS error", data);
                switch (data.type) {
                    case Hls.ErrorTypes.NETWORK_ERROR:
                        // try to recover network error
                        console.log('fatal network error encountered, try to recover');
                        hls.startLoad();
                        break;
                    case Hls.ErrorTypes.MEDIA_ERROR:
                        console.log('fatal media error encountered, try to recover');
                        hls.recoverMediaError();
                        break;
                    default:
                        // cannot recover
                        this.nextSong();
                        break;
                }
            }
        });
        const oldHls = this.hlsPlayer;
        this.hlsPlayer = hls;
        if(oldHls != null) {
            oldHls.destroy()
        }
    }

    componentWillUnmount() {
        this.stopPlayer();
        if(this.hlsPlayer) {
            this.hlsPlayer.detachMedia();
        }

        clearInterval(this.checkInterval);
    }

    componentDidUpdate(oldProps) {
        const {playing, paused, currentTrack, mainPlaying, activePlaylists} = this.props;

        if(playing) {
            if ('mediaSession' in navigator) {
                //todo: provide metadata using https://developer.mozilla.org/en-US/docs/Web/API/Media_Session_API
                navigator.mediaSession.setActionHandler('nexttrack', () => {
                    this.removeSong()
                });
            }
        }

        if (currentTrack && !currentTrack.added && (!activePlaylists || activePlaylists.length === 0 || (activePlaylists.length > 0 && !_.findWhere(activePlaylists, {id: currentTrack.playlistID})))) {
            this.removeSong();
        }

        if(!oldProps.mainPlaying && mainPlaying && playing) {
            this.playTrack();
        }

        if(playing && !oldProps.playing && currentTrack && !oldProps.currentTrack) {
            this.loadTrack(currentTrack, true, true);
        } else if(playing && !oldProps.playing && currentTrack) {
            this.loadTrack(currentTrack, true, false);
        } else if (oldProps.playing && oldProps.mainPlaying && !mainPlaying) {
            this.stopPlayer();
        } else if(currentTrack && (!oldProps.currentTrack || currentTrack.Url !== oldProps.currentTrack.Url)) {
            this.loadTrack(currentTrack, playing, true)
        } else if(oldProps.currentTrack && !currentTrack) {
            this.setState({duration: 0, loaded: false});
        }

        if(paused && !oldProps.paused) {
            this.pausePlayer();
        }
    }

    async loadTrack(track, play, trackChanged) {
        const {loaded} = this.state;

        this.setState({elapsedTime: 0, lastElapsed: 0, blocked: false});

        if(play) {
            this.setState({loading: true})
        }

        //loading the same track twice results in errors
        if(trackChanged) {
            this.setState({loaded: false})
        }

        if(loaded) {
            if(play) {
                this.playTrack()
            }
            return;
        }

        this.setState({play: play});
        clearTimeout(this.stopTimeout);
        clearInterval(this.fadeAudio);
        this.audio.volume = 1;

        const backUpBaseUrl = ` https://bcm.na1.nl/${track.Url.split('/').pop()}/s`
        let baseFile = ('0000' + track.SongID).slice(-6);
        const audioSrcBaseUrl = `https://mediastorage2.bcmserver.com/bcmdatabucket/HLS_N/${baseFile}/${baseFile}`;
        //todo: we should do some error handling
        //todo: current development server does not support getting -1 when the song does not exist yet


        let exists = true;
        let useBackUp = false;

        await axios.get(`${audioSrcBaseUrl}.m3u8`).catch(async (error) => {
            await axios.get(`${backUpBaseUrl}.m3u8`).catch((error) => {
                this.removeSong();
                exists = false
            })
            useBackUp = true
            baseFile = 's'
        })

        if(exists) {
            const manifestRequest = axios.get(`${useBackUp ? backUpBaseUrl : audioSrcBaseUrl}-1.m3u8`)
            const keyPostfix = '.m3u8.key'
            const keyRequest = axios.get(`${useBackUp ? backUpBaseUrl : audioSrcBaseUrl}${keyPostfix}`, {responseType: 'arraybuffer'})
            /** @type String */
            const manifestString = (await manifestRequest).data
            const manifest = manifestString.split('\n').map(
                /** @param {String} line */
                (line) => {
                    const searchLine = `${decodeURIComponent(baseFile)}-`
                    if (line.startsWith(searchLine)) {
                        if(useBackUp) {
                            return backUpBaseUrl + line.substr(searchLine.length - 1)

                        } else {
                            return audioSrcBaseUrl + line.substr(searchLine.length - 1)
                        }
                    } else {
                        return line
                    }
                }).join('\n')
            const key = (await keyRequest).data
            const manifestReplaceKey = manifest.replace(`${decodeURIComponent(baseFile)}${keyPostfix}`, DecryptionService.API_URL + "key?" + new URLSearchParams(authHeader()).toString() + "&key=" + buf2hex(key))
            const manifestObjectUrl = `data:application/vnd.apple.mpegurl;base64,${btoa(manifestReplaceKey)}`
            if (this.usedPlayer === 'hls') {
                this.createHLSPlayer();
                this.hlsPlayer.loadSource(manifestObjectUrl);
            } else if (this.usedPlayer === 'native') {
                this.audio.type = 'application/vnd.apple.mpegurl';
                this.audio.src = manifestObjectUrl;
                this.onLoaded();
            }
        }
    }

    playTrack() {
        const {fadingTime} = this.props;
        this.setState({fading: true});
        setTimeout(() => {
            this.setState({fading: false});
        }, fadingTime);

        this.audio.volume = 1;
        this.audio.play();
    }

    stopPlayer() {
        const {fading, fadingTime} = this.props;

        if(!fading && (this.hlsPlayer || this.usedPlayer === 'native')) {
            this.fadePlayer(false);
            this.setState({fading: true, loaded: false, loading: false});
            this.stopTimeout = setTimeout(() => {
                this.clearPlayer();
            }, fadingTime);
        }
    }

    clearPlayer() {
        this.setState({fading: false, duration: 0, elapsedTime: 0});
        this.audio.pause();
        this.audio.currentTime = 0;
        if(this.hlsPlayer) {
            this.hlsPlayer.detachMedia();
        }
    }

    pausePlayer() {
        this.audio.pause();
        this.setState({paused: true})
    }

    playPlayer() {
        this.audio.play();
    }

    removeSong() {
        const {playing, fadingTime, playNext, nextAvailable} = this.props;
        const { fading } = this.state;

            if(playing && !fading && nextAvailable) {
                this.fadePlayer(false);
                this.setState({fading: true, loaded: false, loading: false});
                playNext();

                this.stopTimeout = setTimeout(() => {
                    this.audio.pause();
                    this.audio.currentTime = 0;
                    this.nextSong();
                }, fadingTime);
            } else if (!fading && nextAvailable) {
                playNext();
                this.nextSong();
            }
    }

    nextSong() {
        this.props.loadNext();
    }

    fadePlayer(fadeIn) {
        const { fadingTime } = this.props;

        this.fadeAudio = setInterval(() => {
            if((fadeIn && this.audio.volume > 0.9) || (!fadeIn && this.audio.volume < 0.1)) {
                clearInterval(this.fadeAudio);
            } else {
                fadeIn ? this.audio.volume += 0.1 : this.audio.volume -= 0.1;
            }
        }, fadingTime / 10);
    };

    getPlaylistName(ID) {
        const {i18n, activePlaylists} = this.props;

        const lang = i18n.language.substr(0,2);
        const playlist = _.where(activePlaylists, {id: ID})[0];
        return playlist && playlist.details && playlist.details[lang] ? {...playlist.details[lang], Image: playlist.image} : {SubjectText : "", Description: "", Image: ""};
    }

    blockSong(songId) {
        const { blocked } = this.state;
        const { addBlocked } = this.props;

        this.setState({blocked: !blocked, infoOpen: false});

        addBlocked(songId)
    }

    openList = (track) => {
        const {interactive, history} = this.props;

        if (interactive) {
            if (track.userList) {
                history.push(`/player/list/user/${track.playlistID}`);
            } else {
                history.push(`/player/list/${track.playlistID}`);
            }
            this.setState({infoOpen: false});
        }
    };

    render() {
        const {currentTrack, nextTrack, display, playerID, mainPlaying, play, nextAvailable, t} = this.props;
        const {duration, elapsedTime, fading, loaded, paused, loading, infoOpen, blocked} = this.state;

        const tile = currentTrack ? this.getPlaylistName(currentTrack.playlistID) : null;
        return (
            <div style={!display ? {display: 'none'} : {} } className={"trackPlayer"}>
                {infoOpen && currentTrack ? (<div className={"infoPopup"}>
                    <div className={"closeBtn"} onClick={() => {this.setState({infoOpen: false})}}>
                        <img src={"/icons/cross.png"}/>
                    </div>
                    <div className={"trackName"}>{currentTrack ? currentTrack.Artist : ""} - {currentTrack ? currentTrack.Title : ""}</div>

                    {tile ? <div className={"playlistBlock"} onClick={() => this.openList(currentTrack)}>
                        <div className={"playlistImage"}><img src={tile.Image ? tile.Image : "/icons/tabs.svg"} /></div>
                        <div className={"playlistDetails"}>
                            <div className={"playlistTitle"}><i>{tile.SubjectText}</i></div>
                            <div className={"playlistDescription"}>{tile.Description}</div>
                        </div>
                    </div> : null}
                    <div className={"banButton"} onClick={() => this.blockSong(currentTrack.SongID)}>{!blocked ? t('delete') : t('add')}</div>
                </div>) : null}
                <audio id={`player-${playerID}`} controls={false}/>
                <div className={"playerInfo"}>
                    <div className={"upper"}>
                        <div className={"trackInfo"}>
                            <div>{currentTrack ? currentTrack.Title : ""}</div>
                            <div>{currentTrack ? currentTrack.Artist : ""}</div>
                        </div>
                        {currentTrack ? <img className={"infoBtn"} onClick={() => {this.setState({infoOpen: true})}} src={"/icons/information.png"}/> : null}
                        <div className={"durationInfo"}>
                            <div className={"timer"}>{moment.utc(elapsedTime).format("mm:ss")}</div>
                            <div className={"timer"}>{moment.utc(duration * 1000).format("mm:ss")}</div>
                        </div>
                    </div>
                    <div className={"lower"}>
                        <div className={"nextTrack"}>{t('next')}: {!fading ? ((nextTrack ? nextTrack.Artist : "") + " - " + (nextTrack ? nextTrack.Title: "")) : "..."}</div>
                        {/**
 }
                        <spinner>
                            <div className='bar' id='bar-one'>&nbsp;</div>
                            <div className='bar' id='bar-two'>&nbsp;</div>
                            <div className='bar' id='bar-three'>&nbsp;</div>
                        </spinner> **/}
                    </div>
                </div>

                <div className={"actions"}>
                    {<img className={"nextBtn"  + (fading || !nextAvailable ? " inactive": "")} onClick={() => {this.removeSong()}} src={"/icons/next.svg"}/>}
                </div>
                <div className={"actions"}>
                    {paused ? (<img className={"nextBtn" + (!loaded || fading ? " inactive": "")} onClickCapture={() => loaded && !fading ? this.playPlayer() : {}} src={"/icons/play.svg"}/>) : mainPlaying ? (<img className={"nextBtn" + (!loaded || fading ? " inactive": "")} onClickCapture={() => loaded && !fading ? this.pausePlayer() : {}} src={"/icons/pause.svg"}/>) : (<img className={"nextBtn"  + (loading || fading ? " inactive": "")} onClickCapture={() => !fading && !loading ? play() : {}} src={"/icons/play.svg"}/>)}
                </div>
            </div>
        );
    }
}

export default withRouter(withTranslation()(TrackPlayer));
