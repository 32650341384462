import React, {Component, useEffect} from 'react';
import {
    withRouter
} from "react-router-dom";
import _ from 'underscore';
import {withTranslation} from "react-i18next";

//web
import './player.scss';

//mobile
import Header from './easy/header';
import MobileMenu from './easy/menu/mobile-menu';
import Menu from "./easy/menu/menu";
import Content from "./easy/content/content";
import MusicPlayer from "./easy/music-player/music-player";
import playlistService from "../services/playlistService";
import userService from "../services/userService";
import authService from "../services/authService";

class Player extends Component {

    constructor(props) {
        super(props);

        this.state = {
            allPlaylists: [],
            currentPlaylist: [],
            tiles: null,
            activePlaylists: [],
            favorites: [],
            shuffledLists: new Map(),
            workingLists: new Map(),
            settings: false,
            isLoading: true,
            screenDimensions: {width: window.innerWidth, height: window.innerHeight},
            songHistory: [],
            blocked: [],
            lastSongs: [],
            playing: false,
            emptyPlayer: 0,
            currentInfo: {current: null, next: null}
        };
    }

    componentDidMount() {
        const {history} = this.props;
        this.fetchUserData();
        this.fetchPlaylists();
        this.useViewport();

        const user = JSON.parse(localStorage.getItem('user'));

        if (!user) {
            history.push("/login")
        } else if (user.isDemo) {
            setTimeout(() => {
                authService.logout();
                window.location.replace("https://bcmstream.com/easy-webbased-trial")
            }, user.expiresAt - Date.now())
        }
    };

    fetchPlaylists = () => {
        playlistService.getAllPlaylists().then(({data}) => {
            this.setState({allPlaylists: data.data})
        }).catch(error => {
            if (error.response) {
                const {status} = error.response;
                if (status === 403 || status === 401 || status === 404) {
                    this.logout();
                } else if (status === 605) {
                    authService.logout();
                    window.location.replace("https://bcmstream.com/easy-webbased-trial")
                }
            }
        });
    }

    fetchUserData = () => {
        userService.fetchUser().then(({data}) => {
            if (data.data) {
                const user = data.data;

                this.setState({
                    favorites: user.favorites ? user.favorites : [],
                    blocked: user.blocked ? user.blocked : [],
                    activePlaylists: user.activePlaylists ? user.activePlaylists : [],
                    user: user,
                    isLoading: false
                }, () => {
                    this.createShuffeledLists();
                });
            }
        }).catch(error => {
            if (error.response) {
                const {status} = error.response;
                if (status === 403 || status === 401 || status === 404) {
                    this.logout();
                } else if (status === 605) {
                    authService.logout();
                    window.location.replace("https://bcmstream.com/easy-webbased-trial")
                }
            }
        })
    };

    logout = () => {
        const {history} = this.props;
        authService.logout();
        history.push("/login")
    };

    addPlaylist = (playlist, user) => {
        let {activePlaylists} = this.state;

        if (_.findWhere(activePlaylists, {id: (playlist.ID ? playlist.ID : playlist.id ? playlist.id : playlist._id)})) {
            activePlaylists = activePlaylists
                .filter(function (list) {
                    return list.id !== (playlist.ID ? playlist.ID : playlist.id ? playlist.id : playlist._id);
                });
        } else {
            activePlaylists.push({
                id: playlist.ID ? playlist.ID : playlist._id,
                details: playlist.Language,
                image: playlist.PictureUrl,
                percentage: 50,
                user: user
            });
        }

        const splitNumber = (parts = 1) => {
            let n = Math.floor(100 / parts);
            const arr = [];
            for (let i = 0; i < parts; i++){
                arr.push(n)
            };
            if(arr.reduce((a, b)=> a + b,0) === 100){
                return arr;
            };
            for(let i = 0; i < parts; i++){
                arr[i]++;
                if(arr.reduce((a, b) => a + b, 0) === 100){
                    return arr;
                };
            };
        };

        const percentages = splitNumber(activePlaylists.length);
        activePlaylists.forEach((playlist, index) => {
            playlist.percentage = percentages[index];
        });

        this.setState({activePlaylists: activePlaylists}, () => {
            this.createShuffeledLists();
        });

        userService.setActivePlaylists(activePlaylists).catch(error => {
            if (error.response) {
                const {status} = error.response;
                if (status === 403 || status === 401 || status === 404) {
                    this.logout();
                } else if (status === 605) {
                    authService.logout();
                    window.location.replace("https://bcmstream.com/easy-webbased-trial")
                }
            }
        })
    };

    addFavorite = (id) => {
        let {favorites} = this.state;
        if (_.contains(favorites, id)) {
            favorites = favorites
                .filter(function (list) {
                    return list !== id;
                });
            userService.removeFavorite(id).catch(error => {
                if (error.response) {
                    const {status} = error.response;
                    if (status === 403 || status === 401 || status === 404) {
                        this.logout();
                    } else if (status === 605) {
                        authService.logout();
                        window.location.replace("https://bcmstream.com/easy-webbased-trial")
                    }
                }
            });
        } else {
            favorites.push(id);
            userService.addFavorite(id).catch(error => {
                if (error.response) {
                    const {status} = error.response;
                    if (status === 403 || status === 401 || status === 404) {
                        this.logout();
                    } else if (status === 605) {
                        authService.logout();
                        window.location.replace("https://bcmstream.com/easy-webbased-trial")
                    }
                }
            });
        }

        this.setState({favorites});
    };

    addBlocked = (id) => {
        let {blocked} = this.state;
        if (_.contains(blocked, id)) {
            blocked = blocked
                .filter(function (song) {
                    return song !== id;
                });
            userService.removeBlocked(id).catch(error => {
                if (error.response) {
                    const {status} = error.response;
                    if (status === 403 || status === 401 || status === 404) {
                        this.logout();
                    } else if (status === 605) {
                        authService.logout();
                        window.location.replace("https://bcmstream.com/easy-webbased-trial")
                    }
                }
            });
        } else {
            blocked.push(id);
            userService.addBlocked(id).catch(error => {
                if (error.response) {
                    const {status} = error.response;
                    if (status === 403 || status === 401 || status === 404) {
                        this.logout();
                    } else if (status === 605) {
                        authService.logout();
                        window.location.replace("https://bcmstream.com/easy-webbased-trial")
                    }
                }
            });
        }

        this.setState({blocked});
    };


    changePercentage = (id, up) => {
        let {activePlaylists, shuffledLists} = this.state;
        let amount = -1;
        activePlaylists.forEach((playlist) => {
            if (up && playlist.percentage >= 1) {
                amount += 1;
            } else if (!up) {
                amount += 1;
            }
        })

        const playlist = _.findWhere(activePlaylists, {id: id});
        const blockingList = activePlaylists.filter((list) => {
            return (list.percentage - amount) <= 0
        });

        if (!(blockingList.length > 0 && (up && _.findWhere(blockingList, {_id: id}))) && !(!up && (playlist.percentage - amount) <= 0) && !(up && ((playlist.percentage + amount) > (100 - amount)) && amount !== 0)) {
            activePlaylists.forEach((playlist) => {
                if (playlist.id === id) {
                    if (up) {
                        playlist.percentage += amount;
                    } else {
                        playlist.percentage -= amount;
                    }
                } else {
                    if (up) {
                        if (playlist.percentage >= 1) {
                            playlist.percentage -= 1;
                        }
                    } else {
                        playlist.percentage += 1;
                    }
                }
            });
        }
        this.setState({activePlaylists: activePlaylists});
        this.composePlaylist(shuffledLists, activePlaylists);
        userService.setActivePlaylists(activePlaylists).catch(error => {
            if (error.response) {
                const {status} = error.response;
                if (status === 403 || status === 401 || status === 404) {
                    this.logout();
                } else if (status === 605) {
                    authService.logout();
                    window.location.replace("https://bcmstream.com/easy-webbased-trial")
                }
            }
        });
    };

    moveSong = (oldIndex, newIndex, playNow) => {
        const {currentPlaylist, songHistory} = this.state;

        let newPlaylist = [...currentPlaylist]
        let newHistory = [...songHistory];

        if (oldIndex !== newIndex) {
            if (newIndex !== undefined) {
                if (oldIndex === 0) {
                    const song = songHistory.slice(-1)[0];
                    newPlaylist.splice(newIndex, 0, song);
                    newHistory.pop();
                    this.setState({currentPlaylist: newPlaylist, songHistory: newHistory}, () => {
                        this.loadNextTrack();
                    })
                } else {
                    const song = newPlaylist.splice(oldIndex - 1, 1)[0];
                    if (newIndex === 0) {
                        const oldSong = songHistory.slice(-1)[0];
                        newPlaylist = [song, oldSong, ...newPlaylist]
                        newHistory.pop();
                        this.setState({songHistory: newHistory, currentPlaylist: newPlaylist}, () => {
                            this.loadNextTrack(null, !!playNow);
                        })
                    } else {
                        newPlaylist.splice(newIndex - 1, 0, song);
                        this.setState({currentPlaylist: newPlaylist})
                    }
                }
            } else {
                if (oldIndex === 1) {
                    newHistory.pop();
                    this.setState({songHistory: newHistory}, () => {
                        this.loadNextTrack();
                    })
                } else {
                    newPlaylist.splice(oldIndex - 2, 1);
                    this.setState({currentPlaylist: newPlaylist})
                }
            }
        } else if (playNow) {
            this.loadNextTrack(null, true, true);
        }
    };

    addToPlaylist = (song, next, now) => {
        let {currentPlaylist, songHistory} = this.state;
        let newPlaylist = [...currentPlaylist];
        let newHistory = [...songHistory]
        const newSong = {...song, added: true};

        if (next) {
            if (newHistory.length > 1) {
                const oldSong = songHistory.slice(-1)[0];
                newPlaylist = [newSong, oldSong, ...newPlaylist]
                newHistory.pop();
            } else {
                newPlaylist = [newSong, ...newPlaylist];
            }

            this.setState({songHistory: newHistory, currentPlaylist: newPlaylist}, () => {
                this.loadNextTrack(null, !!now);
            })
        } else {
            let lastIndex = -1;

            newPlaylist.forEach((item, index) => {
                if (item.added) {
                    lastIndex = index;
                }
            })
            newPlaylist.splice(lastIndex + 1, 0, newSong);
            this.setState({currentPlaylist: newPlaylist});
        }
    }

    async createShuffeledLists() {
        const {activePlaylists, currentPlaylist} = this.state;
        const lists = new Map();

        for (const playlist of activePlaylists) {
            let list = null;

            if (playlist.user) {
                list = await playlistService.getUserPlaylist(playlist.id).then(({data}) => {
                    return data.data.Songs;
                }).catch((error) => {
                    if (error.response) {
                        const {status} = error.response;
                        if (status === 404) {
                            this.setState({loading: false})
                        } else if (status === 403 || status === 401) {
                            this.logout();
                        } else if (status === 605) {
                            authService.logout();
                            window.location.replace("https://bcmstream.com/easy-webbased-trial")
                        }
                    }
                });
            } else {
                list = await playlistService.getPlaylist(playlist.id).then(({data}) => {
                    return data.data.Songs;
                }).catch((error) => {
                    if (error.response) {
                        const {status} = error.response;
                        if (status === 404) {
                            const pl = {
                                ID: playlist.id
                            }
                            this.addPlaylist(pl);
                        } else if (status === 403 || status === 401) {
                            this.logout();
                        } else if (status === 605) {
                            authService.logout();
                            window.location.replace("https://bcmstream.com/easy-webbased-trial")
                        }
                    }
                });
            }

            if (list) {
                const processed = list.map((item) => {
                    return {...item, playlistID: playlist.id}
                })
                lists.set(playlist.id, _.shuffle(processed));
            }
        }

        if (lists.size > 0) {
            this.setState({shuffledLists: lists});
            this.composePlaylist(lists, activePlaylists, true);
        } else {
            const list = currentPlaylist.filter(song => song.added);
            this.setState({shuffledLists: new Map(), workingLists: new Map(), currentPlaylist: list});
        }
    }

    getNextSong() {
        const {currentPlaylist, shuffledLists, activePlaylists, songHistory, emptyPlayer} = this.state;
        let song = null;
        let history = songHistory;
        let playlist = currentPlaylist

        if (activePlaylists.length > 0 || this.state.currentPlaylist.length > 0) {
            while (song === null) {
                if (activePlaylists.length > 0 && this.state.currentPlaylist.length < 3) {
                    playlist = this.composePlaylist(shuffledLists, activePlaylists);
                    this.setState({currentPlaylist: playlist});
                }

                song = this.state.currentPlaylist.shift();

                if (this.state.songHistory.length > 10) {
                    history = [];
                    this.setState({songHistory: history});
                }

                if (song) {
                    if (!song.Url && song.Uri) {
                        song.Url = song.Uri;
                    }

                    if (!song.added && !song.userList && this.state.songHistory.filter(s => s.SongID === song.SongID).length > 0 || this.state.blocked.filter(b => b === song.SongID.toString()).length > 0) {
                        song = null;
                    } else {
                        history.push(song)
                    }
                }
            }

            this.setState({currentPlaylist: playlist, songHistory: history, emptyPlayer: (emptyPlayer > 0 ? emptyPlayer - 1 : 0)});
            return song;
        } else {
            if (emptyPlayer < 2) {
                this.setState({emptyPlayer: emptyPlayer + 1})
            }
            return null;
        }
    }


    mergeAndDistributeArrays(array1, array2) {
        const [long, short] =
            array1.length >= array2.length ? [array1, array2] : [array2, array1];

        const interval = long.length / (short.length + 1);

        const merged = [...long];

        short.forEach((value, index) => {
            const insertAt = Math.ceil(interval * (index + 1));

            merged.splice(insertAt + index, 0, value);
        });

        return merged;
    }

    composePlaylist(shuffledLists, activePlaylists, initial = false) {
        const {workingLists, currentPlaylist} = this.state;
        const processedPlaylists = [];

        //activePlaylists.filter()
        activePlaylists.forEach(playlist => {
            let workingList = workingLists.get(playlist.id);

            if (!workingList) {
                workingList = [];
            }

            if (workingList.length < playlist.percentage) {
                    const list = shuffledLists.get(playlist.id);
                    if(list.length > 0) {
                        const multiplier = Math.ceil(playlist.percentage / list.length);

                        for (let i = 0; i < multiplier; i++) {
                            workingList = workingList.concat(list);
                        }
                    }
            }
            processedPlaylists.push(workingList.splice(0, playlist.percentage));
            workingLists.set(playlist.id, workingList)
        });
        let playlist = [];
        let oldPlaylist = currentPlaylist.filter(song => song.added)
        if (!initial) {
            playlist = [...oldPlaylist]
        }

        for (let i = 0; i < processedPlaylists.length; i++) {
            var array2 = processedPlaylists[i],
                result = playlist = this.mergeAndDistributeArrays(playlist, array2);
            playlist = result;
        }

        if (initial) {
            playlist = oldPlaylist.concat(playlist);
        }

        this.setState({currentPlaylist: playlist, workingLists: workingLists})
        return playlist
    }

    setCurrentInfo(currentInfo) {
        this.setState({currentInfo})
    }
    useViewport = () => {
        const handleWindowResize = () => this.setState({
            screenDimensions: {
                width: window.innerWidth,
                height: window.innerHeight
            }
        });
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }

    render() {
        const {
            allPlaylists,
            currentPlaylist,
            activePlaylists,
            tiles,
            favorites,
            screenDimensions,
            user,
            songHistory,
            emptyPlayer,
            currentInfo
        } = this.state;
        const mobile = screenDimensions.width < screenDimensions.height;

        if (user) {
            const interactive = user.interactive;
            return (
                <div className={"playerContainer" + (mobile ? " mobile" : "")}>
                    {!mobile ? <Menu active={activePlaylists.length} interactive={interactive}/> : null}
                    <div className={"content"}>
                        <Header user={user} logout={this.logout} mobile={mobile}/>
                        <Content allPlaylists={allPlaylists} active={activePlaylists} favorites={favorites}
                                 interactive={interactive}
                                 playlist={currentPlaylist}
                                 emptyPlayer={emptyPlayer}
                                 songHistory={songHistory}
                                 addFavorite={this.addFavorite}
                                 addPlaylist={this.addPlaylist} changePercentage={this.changePercentage}
                                 currentInfo={currentInfo}
                                 moveSong={this.moveSong} tiles={tiles} mobile={mobile} addToPlaylist={this.addToPlaylist}/>
                        <MusicPlayer getNextSong={() => this.getNextSong()} playlist={currentPlaylist}
                                     activePlaylists={activePlaylists} addBlocked={this.addBlocked}
                                     setLoadNext={load => this.loadNextTrack = load} setCurrentInfo={(info) => this.setCurrentInfo(info)} interactive={interactive}/>
                    </div>
                    {mobile ?
                        <MobileMenu active={activePlaylists.length} interactive={interactive}/> : null}
                </div>
            )
        } else {
            return (
                <div className={"playerContainer" + (mobile ? " mobile" : "")}>
                    <div className={"loadingSpinner"}>
                        <div className="lds-roller">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </div>
            );
        }

    }
}

export default withRouter(withTranslation()(Player));
